const EnergyDirection = Object.freeze({
    CONSUMPTION: 1,
    PRODUCTION: 2,
    tryFrom(value) {
        return Object.keys(this).find((key) => this[key] === value);
    },
    getDescription(value) {
        switch (value) {
            case this.CONSUMPTION:
                return 'Verbrauch';
            case this.PRODUCTION:
                return 'Produktion';
            default:
                return null;
        }
    },
});

export default EnergyDirection;
