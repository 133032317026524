<template>
    <div id="app">
        <router-view />
        <ErrorHandler />
    </div>
</template>

<script>
    import ErrorHandler from './components/ErrorHandler.vue';
    
    export default {
        name: 'App',
        components: {
            ErrorHandler
        }
    };
</script>

<style scoped></style>
