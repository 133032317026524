import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia';
import User from '@/models/User.js';
import axios from '@/axios.js';
import * as Sentry from '@sentry/vue';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        loggedInUserId: null,
    }),
    getters: {
        isAuthenticated: (state) => !!state.loggedInUserId,
        authenticatedUser: (state) => {
            return User.repo().find(state.loggedInUserId);
        },
    },
    actions: {
        async login(credentials) {
            await axios.post('/login', {
                email: credentials.email,
                password: credentials.password,
            });
        },
        async logout(shouldRedirect = true) {
            try {
                // Make the API call first
                await axios.post('/logout');
            } catch (error) {
                // Still proceed with redirect even if API call fails
                console.warn('Logout API call failed:', error);
            } finally {
                if (shouldRedirect) {
                    // Full page reload will clear all state automatically
                    window.location.href = '/';
                }
            }
        },
        refreshMe(token = null) {
            const url = `/me${token ? `?token=${token}` : ''}`;
            return axios.get(url).then(({ data }) => {
                const user = User.repo().save(data.data);
                Sentry.setUser({ id: user.id });
                this.loggedInUserId = user.id;
                return user;
            });
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
