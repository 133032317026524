<!--
  - /*******************************************************
  -  * Copyright (C) 2018-2020 webcrab GmbH
  -  * This file is part of ista VDM 2.0.
  -  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  * Proprietary and confidential
  -  * Written by <phillip@webcrab.at>
  -  *******************************************************/
  -->

<template>
    <div class="panel">
        <div class="panel-body">
            <dl class="dl-horizontal">
                <dt>Kunden&shy;nummer</dt>
                <dd>{{ user.debtornumber }}</dd>
                <dt>Name</dt>
                <dd>{{ user.name }}</dd>
                <dt>Adresse</dt>
                <dd>{{ user.street }}, {{ user.postalcode }} {{ user.city }}</dd>
            </dl>
        </div>
    </div>
</template>

<script>
    import User from '../../models/User';

    export default {
        name: 'PropertyManagerInfo',
        computed: {
            user() {
                return User.me();
            },
        },
    };
</script>

<style scoped></style>
