import { Model, useRepo } from 'pinia-orm';
import Meter from './Meter';

export default class EnergyCommunity extends Model {
    static entity = 'energyCommunities';

    static fields() {
        return {
            id: EnergyCommunity.attr(''),
            name: EnergyCommunity.string(''),
            external_community_id: EnergyCommunity.string(null),
            external_eda_partner_id: EnergyCommunity.attr(null),
            participation_model: EnergyCommunity.attr('static'),
            community_type: EnergyCommunity.attr('csc'),
            first_billing_period: EnergyCommunity.attr(null),
            billing_month: EnergyCommunity.number(null),
            operator_company_name: EnergyCommunity.string(null),
            operator_street: EnergyCommunity.string(null),
            operator_postcode: EnergyCommunity.string(null),
            operator_city: EnergyCommunity.string(null),
            operator_contact_name: EnergyCommunity.string(null),
            meta: EnergyCommunity.attr(null),
            meters: EnergyCommunity.hasMany(Meter, 'energy_community_id'),
            consumptionMeters: EnergyCommunity.hasMany(Meter, 'energy_community_id'),
            productionMeters: EnergyCommunity.hasMany(Meter, 'energy_community_id'),
            responsibleEnergyCommunityManager: EnergyCommunity.attr(null),
        };
    }

    static repo() {
        return useRepo(EnergyCommunity);
    }
}
