import { defineAsyncComponent } from 'vue';
import User from '@/models/User.js';
import { useDialog } from 'primevue/usedialog';
import { useAuthStore } from '@/stores/auth';

// Lazy load modal components
const DataAccessModal = defineAsyncComponent(
    () => import('@/components/Tenant/DataAccessModal.vue')
);

const DataAccessErrorModal = defineAsyncComponent(
    () => import('@/components/Tenant/DataAccessErrorModal.vue')
);

export function useDataAccessGuard(router) {
    router.beforeEach(async (to, from, next) => {
        // Skip if not tenant route or already has access
        if (!to.name?.startsWith('tenant') || User.me().hasDataAccessEnabled) {
            next();
            return;
        }

        const dialog = useDialog();
        const authStore = useAuthStore();

        // Block initial navigation
        from.name === null ? next('/') : next(false);

        // Show access dialog
        dialog.open(DataAccessModal, {
            props: {
                header: 'Zustimmungserklärung laut DSG und DSGVO',
                modal: true,
                closeOnEscape: false,
                dismissableMask: false,
                closable: false,
                style: { width: '50vw' },
            },
            onClose: async () => {
                if (User.me().hasDataAccessEnabled) {
                    router.push(to.path);
                } else {
                    await authStore.logout(false);
                    dialog.open(DataAccessErrorModal, {
                        props: {
                            header: 'Einwilligung nicht erteilt',
                            modal: true,
                            closeOnEscape: false,
                            dismissableMask: false,
                            style: { width: '50vw' },
                        },
                        onClose: () => (window.location.href = '/'),
                    });
                }
            },
        });
    });
}

export default useDataAccessGuard;
