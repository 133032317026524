/*
 * ******************************************************
 *  * Copyright (C) 2018-2020 webcrab GmbH
 *  * This file is part of ista VDM 2.0.
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by <phillip@webcrab.at>
 *  ******************************************************
 */

import { Model, useRepo } from 'pinia-orm';
import EnergyCarrier from './EnergyCarrier';
import EnergyDirection from '../enums/EnergyDirection';
import Flat from '@/models/Flat.js';
import Property from '@/models/Property.js';
import SmartMeter from './SmartMeter';

export default class Meter extends Model {
    static entity = 'meters';

    static fields() {
        return {
            id: this.attr(''),
            uuid: this.attr(''),
            description: this.string(null),
            serialnumber: this.string(null),
            consumption: this.number(0),
            location: this.string(''),
            area: this.number(0),
            energy_carrier_id: this.attr(null),
            energy_direction: this.number(null),
            meters_id: this.attr(null),
            meters_type: this.attr(null),
            precision: this.attr(null),
            settings: this.attr({}),
            data_received_at: this.attr(null),
            meta: this.attr(null),

            // Relationships
            energyCarrier: this.belongsTo(EnergyCarrier, 'energy_carrier_id'),
            object: this.morphTo([Flat, Property], 'meters_id', 'meters_type'),
            smartMeter: this.hasOne(SmartMeter, 'meter_id'),
        };
    }

    get energyDirection() {
        return this.energy_direction === EnergyDirection.CONSUMPTION ? 'Verbrauch' : 'Produktion';
    }

    static repo() {
        return useRepo(Meter);
    }
}
