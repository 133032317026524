<template>
  <!-- Empty template - this is a utility component with no UI -->
</template>

<script>
import isUndefined from 'lodash-es/isUndefined';
import Axios from '../axios';

export default {
  name: 'ErrorHandler',
  created() {
    // Configure HTTP interceptors
    Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const errorMessages = {
          network: {
            severity: 'error',
            summary: 'Netzwerkfehler',
              detail: 'Bitte prüfen Sie Ihre Internetverbindung.'
          },
          server: {
            severity: 'error',
            summary: 'Ein Fehler ist aufgetreten',
              detail: 'Bitte versuchen Sie es später erneut.'
          },
          auth: {
            severity: 'warn',
            summary: 'Authentifizierungsfehler',
              detail: 'Sie wurden aus Sicherheitsgründen abgemeldet. Bitte melden Sie sich erneut an.'
          },
        };

        if (isUndefined(error.response)) {
          this.$toast.add(errorMessages.network);
        } else if ([500, 429].includes(error.response.status)) {
          this.$toast.add(errorMessages.server);
        } else if ([401, 403].includes(error.response.status)) {
          this.$toast.add(errorMessages.auth);
          this.$router.push({ name: 'home' });
        }

        return Promise.reject(error);
      }
    );
  },
};
</script>
