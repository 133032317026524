<template>
    <div class="breadcrumbs">
        <router-link v-if="isAuthenticated" :to="userHomeUrl" class="hidden-print mr-5">
            <font-awesome-icon icon="fa-solid fa-home" class="fa-xs" />
        </router-link>
        <a class="hidden-print is-clickable mr-5" @click="$router.go(-1)">
            <font-awesome-icon icon="fa-solid fa-arrow-left" />
        </a>
    </div>
</template>

<script>
    import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
    import User from '../models/User';
    import { defineComponent } from 'vue';
    import { mapState } from 'pinia';
    import { useAuthStore } from '@/stores/auth.js';

    export default defineComponent({
        name: 'Breadcrumb',
        computed: {
            userHomeUrl() {
                if (User.me().group_id === 3 && this.$route.params.flatId) {
                    return `/tenant/${this.$route.params.flatId}/dashboard`;
                }
                return User.me().homeUrl;
            },
            ...mapState(useAuthStore, ['isAuthenticated']),
        },
    });
</script>

<style scoped lang="scss">
    @import '~/resources/sass/variables.scss';

    a {
        text-decoration: none;
    }

    .breadcrumbs {
        display: inline;
    }

    svg {
        padding: 0 5px;
        color: $istaBase60;
    }

    span {
        font-size: 12pt;
    }

    .back-button {
        cursor: pointer;
    }
</style>
