import { Model, useRepo, useStoreActions } from 'pinia-orm';
import Flat from './Flat';
import Contract from './Contract';
import axios from '../axios';
import { useAuthStore } from '@/stores/auth.js';

export default class User extends Model {
    static entity = 'users';

    static fields() {
        return {
            id: User.attr(null),
            uuid: User.attr(''),
            name: User.string(''),
            email: User.string(null),
            debtornumber: User.string(null),
            street: User.string(''),
            postalcode: User.string(''),
            city: User.string(''),
            receive_notifications: User.boolean(null),
            group_id: User.number(0),
            manager_id: User.attr(null),
            // manager: this.belongsTo(User, 'manager_id'),
            tenantContract: User.hasOne(Contract, 'user_id'),
            flat_id: User.attr(null),
            flat: User.belongsTo(Flat, 'flat_id'),
            token: User.string(null),
            created_at: User.attr(''),
            data_access_granted: User.boolean(null),
            data_access_confirmed_at: User.attr(null),
            active: User.boolean(false),
            meta: User.attr({}),
            notification_subscriptions: User.attr({}),
        };
    }
    static piniaOptions = {
        actions: {
            ...useStoreActions(),
            resetPassword(user) {
                return axios
                    .post('/reset_password', {
                        email: user.email,
                        password: user.password,
                        password_confirmation: user.password_confirmation,
                        token: user.token,
                    })
                    .then(({ data }) => data);
            },
            acceptDataAccess(user) {
                return axios.post(`/users/${user.id}/acceptDataAccess`).then(({ data }) => {
                    User.repo().save(data.data);
                });
            },

            revokeDataAccessAndDisableAccount(user) {
                return axios.post(`/users/${user.id}/revokeDataAccess`).then(({ data }) => {
                    User.repo().save(data.data);
                });
            },

            makeActive(value, tenant) {
                return axios
                    .put(`/users/${tenant.id}`, {
                        active: !!value,
                    })
                    .then(({ data }) => {
                        User.repo().save(data.data);
                    });
            },

            requestNewToken(user) {
                return axios.post(`/users/${user.id}/resetToken`).then(({ data }) => {
                    User.repo().save(data.data);
                });
            },
        },
    };

    static me() {
        return User.repo().find(useAuthStore().loggedInUserId);
    }

    get homeUrl() {
        switch (this.group_id) {
            case 1:
                return '/admin/dashboard';
            case 2:
            case 4:
                return '/property-manager/dashboard';
            case 3:
                return `/tenant/${this.flat_id}/dashboard`;
        }
        return null;
    }

    get group() {
        switch (this.group_id) {
            case 1:
                return 'admin';
            case 2:
            case 4:
                return 'propertyManager';
            case 3:
                return 'tenant';
        }
        return null;
    }

    get baseRouteName() {
        switch (this.group_id) {
            case 1:
                return 'admin';
            case 2:
            case 4:
                return 'property-manager.property';
            case 3:
                return 'tenant';
        }
        return null;
    }

    get hasManager() {
        return this.manager_id != null;
    }

    get isManager() {
        return this.manager_id == null;
    }

    get isTenant() {
        return this.group_id === 3;
    }

    get hasDataAccessEnabled() {
        return this.data_access_granted === true;
    }

    static repo() {
        return useRepo(User);
    }
}
