import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes.js';

export function createRouter() {
    return new _createRouter({
        history: createWebHistory(),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }
            return { top: 0, left: 0 };
        },
        routes,
    });
}
