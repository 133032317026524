import { acceptHMRUpdate, defineStore } from 'pinia';

export const usePropertyCompareStore = defineStore({
    id: 'property-compare',
    state: () => ({
        compareProperties: [],
    }),
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePropertyCompareStore, import.meta.hot));
}
