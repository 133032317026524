<template>
    <div>
        <h3 class="mb-4">
            <breadcrumb />
            Hilfe
        </h3>
        <article class="has-background-white has-text-body p-4">
            <p>
                <b
                    >Was bedeutet Verbrauchsdatenmonitoring (<strong class="fg-istaGreen100"
                        >VDM</strong
                    >)?<br /> </b
                ><strong class="fg-istaGreen100">VDM</strong> ist die regelmäßige Überwachung der
                monatlichen Energieverbrauchsdaten in Ihrem Haushalt. <br />Konkret geht es um
                Ablesedaten für&nbsp;
            </p>
            <ol type="disc">
                <li>
                    <p>Heizenergieverbrauch in kWh</p>
                </li>
                <li>
                    <p>Warmwasser- und</p>
                </li>
                <li>
                    <p>Kaltwasserverbrauch in m³</p>
                </li>
            </ol>
            <p>
                <b>Was ist <strong class="fg-istaGreen100">VDM</strong> von ista?</b>
            </p>

            <p>
                Das <strong class="fg-istaGreen100">VDM</strong> von ista ist eine innovative
                Internet-Lösung mit der Sie Ihre monatlichen Energieverbrauchsdaten für Heizung,
                Warmwasser und Kaltwasser jederzeit online vergleichen und analysieren können –
                einfach, schnell und sicher.
            </p>

            <p>
                <b>Wann kann ich <strong class="fg-istaGreen100">VDM</strong> von ista nutzen?</b>
            </p>

            <p>
                <strong class="fg-istaGreen100">VDM</strong> ist ein neues Produkt von ista und kann
                überall dort genutzt werden, wo Liegenschaft von ista abgelesen und abgerechnet
                werden. <br />&gt; Weitere Informationen erhalten Sie bei Ihrem <br />&nbsp;&nbsp;
                Hausverwalter oder bei ista direkt.
            </p>

            <p>
                <b>Was kann <strong class="fg-istaGreen100">VDM</strong> von ista?</b>
            </p>

            <p>
                Mit <strong class="fg-istaGreen100">VDM</strong> sehen Sie die monatlichen
                Energieverbrauchsdaten vom aktuellen Jahr und den letzten 2 Jahren. Nach der
                monatlichen Erfassung der Heizungs-, Warmwasser- und Kaltwasserverbrauchsdaten sehen
                Sie die aktuellen Daten absolut und in Vergleich zum Vormonat, Vorjahr und zum
                Durchschnitt der Liegenschaft.
            </p>

            <p>
                Darüber hinaus steht Ihnen eine graphische Verbrauchsanalyse zur Verfügung.
                Zusätzlich finden Sie Energiespartipps, weiterführende Informationen und alle
                Kontaktdaten von ista-Österreich.
            </p>

            <p>
                <b>Was mache ich mit der Information?</b>
            </p>

            <p>
                Die Energieverbrauchsdaten dienen zum Vergleich mit dem Vormonat, dem Vorjahr und
                mit dem Durchschnitt zur Liegenschaft. Besonders wichtig ist der Verbrauchwert je m²
                weil er einen objektiven Vergleich zwischen unterschiedlich großen Liegenschaften
                ermöglicht. Der Vergleich zu unterschiedlich warmen Jahren liefert der
                Verbrauchswert je m² bereinigt um die Heizgradtage (HGT).
            </p>

            <p>
                Liegt Ihre Verbrauch unter den Vergleichswerten haben Sie Ihren Energieverbrauch
                <b>gesenkt</b> – Gratulation sie haben die Energieeffizienz gesteigert!
            </p>

            <p>
                Bleiben die Verbrauchswerte <b>gleich oder steigen</b> diese an so verbrauchen Sie
                mehr Energie und haben mit höheren Kosten zu rechnen. Bitte überprüfen Sie Ihr
                Verbrauchsverhalten. Evaluieren Sie auch welchen der vorgeschlagenen
                Energiesparmaßnahmen Sie umsetzen können.
            </p>
            <ul>
                <li>
                    <p>
                        Sollte die Maßnahmen nicht den gewünschten Effekt haben nehmen Sie Kontakt
                        mit Ihrem Hausverwalter oder mit ista-Österreich auf.
                    </p>
                </li>
            </ul>
            <p>
                <b>Was bedeutet Energieeffizienz (EEFF)?</b>
            </p>

            <p>
                Energieeffizienz bedeutet verbesserte Nutzung der eingesetzten Energie z.B. wenn man
                durch bessere Isolierung eines Gebäudes die gleiche Raumwärme bei weniger
                Energieverbrauch erreichen kann. Zum Vergleich der Energieeffizienz liefert
                <strong class="fg-istaGreen100">VDM</strong> die Kenngröße kWh/m² für Heizenergie
                und m³/m² für Wasser.
            </p>

            <p>
                <b>Was bedeutet Heizgradtage (HGT)?</b>
            </p>

            <p>
                Das Klima ändert sich jedes Jahr und ist in jeder Region unterschiedlich. Um den
                Heizenergieverbrauch vergleichbar zu machen werden so genannten „Heizgradtage“ (HGT
                12/20) des aktuellen Bezugsjahrs von der Zentralanstalt für Meteorologie und
                Geodynamik (ZAMG) ermittelt und mit dem langjährigen Mittel (Referenzwert)
                multipliziert.
            </p>

            <p>
                Die jährlichen Heizgradtage sind die Summe der Differenzen zwischen der
                Tagesmitteltemperatur und der angestrebten Raumtemperatur (20° C) von allen Tagen,
                an denen die Tagesmitteltemperatur weniger als 12 °C beträgt.
            </p>

            <p>
                <b>Wer hat Zugang zu meinen Daten?</b>
            </p>

            <p>
                Bei der Entwicklung von <strong class="fg-istaGreen100">VDM</strong> haben wir
                größten Wert auch Sicherheit und Schutz Ihrer Daten gelegt. Die abgelesenen
                Energieverbrauchsdaten werden in unserem Abrechnungssystem gespeichert und nur zu
                Abrechnungszwecken verwendet.
            </p>

            <p>
                Wenn Sie das <strong class="fg-istaGreen100">VDM</strong> nutzen wollen erteilen Sie
                uns mit der Vertragsunterzeichnung auch die Erlaubnis Ihre Energieverbrauchsdaten
                zum Zwecke der Visualisierung in das
                <strong class="fg-istaGreen100">VDM</strong> überzuleiten.
            </p>

            <p>
                Zugriff zum <strong class="fg-istaGreen100">VDM</strong> und den
                Energieverbrauchsdaten erhalten Sie nach Zusendung des Freischaltcodes und
                Registrierung durch Sie.
            </p>

            <p>
                Um Sie gut unterstützen zu können hat ein Mitarbeiter der ista-Hotline Zugriff auf
                die Abrechnungs- und Energieverbrauchs-daten.
            </p>

            <p>
                Zusätzlich können Sie Ihrem Hausverwalter Zugriff auf Ihre Energieverbrauchsdaten
                genehmigen, damit dieser Sie beim Energiesparen unterstützen kann.&nbsp;&nbsp;
            </p>

            <p>
                <b>Wo erhalte ich zusätzliche Information?</b>
            </p>

            <p>
                Zusätzliche Informationen zu <strong class="fg-istaGreen100">VDM</strong> erhalten
                Sie bei der ista-Hotline unter 050 230 230 370, bei Ihrem ista-Kundenbetreuer oder
                bei Ihrem Hausverwalter.
            </p>

            <p>Wir wünschen Ihnen viel Erfolg beim Energiesparen</p>

            <p>Ihr ista <strong class="fg-istaGreen100">VDM-Team</strong></p>
        </article>
    </div>
</template>

<script>
    export default {
        name: 'Help',
    };
</script>

<style scoped></style>
