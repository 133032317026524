import { useAuthStore } from '@/stores/auth.js';
import User from '@/models/User.js';

export default [
    {
        path: '/',
        alias: '/login',
        name: 'home',
        component: () => import('./Pages/Login.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('./Pages/Contact.vue'),
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: () => import('./Pages/TermsOfUse.vue'),
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: () => import('./Pages/Imprint.vue'),
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('./Pages/Privacy.vue'),
    },
    {
        path: '/release-notes',
        name: 'release-notes',
        component: () => import('./Pages/ReleaseNotes.vue'),
    },
    {
        path: '/tenant',
        name: 'tenant',
        redirect: { name: 'tenant.dashboard' },
        component: () => import('./layouts/default.vue'),
        meta: { requiresAuth: true, requiresAuthGroup: [3] },
        children: [
            {
                path: 'flats',
                name: 'tenant.flats',
                components: {
                    default: () => import('./Pages/Tenant/FlatOverview.vue'),
                    info: () => import('./components/FlatSearch.vue'),
                },
            },
            {
                path: 'help',
                name: 'tenant.help',
                components: { default: () => import('./Pages/Tenant/Help.vue') },
            },
            {
                path: 'profile',
                name: 'tenant.profile',
                components: { default: () => import('./Pages/Profile.vue') },
            },
            {
                path: 'save-energy-tips-flat',
                name: 'tenant.save-energy-tips-flat',
                components: {
                    default: () => import('./Pages/Flats/SaveEnergyTipsFlat.vue'),
                },
            },
            {
                path: 'dashboard',
                name: 'tenant.dashboard',
                components: {
                    default: () => import('./Pages/Tenant/Dashboard.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                beforeEnter(to, from, next) {
                    if (!to.params.flatId) {
                        const flatId = User.me().flat_id;
                        next({ name: 'tenant.flat.dashboard', params: { flatId } });
                    } else {
                        next();
                    }
                },
            },
            {
                path: ':flatId(\\d+)/consumption-analysis',
                name: 'tenant.flat.consumption-analysis',
                components: {
                    default: () => import('./Pages/Tenant/FlatConsumptionAnalysisPage.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                    info: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: ':flatId(\\d+)/analysis/smart-meter',
                name: 'tenant.flat.smart-meter-analysis',
                components: {
                    default: () => import('./Pages/Flats/FlatSmartMeterAnalysisPage.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        objectType: 'flats',
                        objectId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: ':flatId(\\d+)/meters',
                name: 'tenant.flat.meters',
                components: {
                    default: () => import('./Pages/Flats/FlatMeterOverview.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    info: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: ':flatId(\\d+)/smart-meters',
                name: 'tenant.flat.smart-meters',
                components: {
                    default: () => import('./Pages/Tenant/FlatSmartMeterManagementPage.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        objectType: 'flats',
                        objectId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: ':flatId(\\d+)/smart-meters/:smartMeterId(\\d+)',
                name: 'tenant.flat.smart-meters.import-data',
                components: {
                    default: () => import('./Pages/Meters/SmartMeterDataImportOverview.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        objectType: 'flats',
                        objectId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: ':flatId(\\d+)/room-analysis',
                name: 'tenant.flat.room-analysis',
                components: {
                    default: () => import('./Pages/Flats/FlatRoomAnalysis.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                        enableRoomEdit: true,
                    }),
                },
            },
            {
                path: ':flatId(\\d+)/dashboard',
                name: 'tenant.flat.dashboard',
                components: {
                    default: () => import('./Pages/Tenant/Dashboard.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
        ],
    },
    {
        path: '/property-manager',
        component: () => import('./layouts/default.vue'),
        redirect: { name: 'property-manager.dashboard' },
        meta: { requiresAuth: true, requiresAuthGroup: [2, 4] },
        children: [
            {
                path: 'dashboard',
                name: 'property-manager.dashboard',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyManagerDashboard.vue'),
                    info: () => import('./components/PropertyManager/PropertyManagerInfo.vue'),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/analyse',
                name: 'property-manager.property.consumption-analysis',
                components: {
                    default: () =>
                        import('./Pages/PropertyManager/PropertyConsumptionAnalysis.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/edit',
                name: 'property-manager.property.edit',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyEdit.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        propertyId: parseInt(route.params.propertyId, 10),
                    }),
                    info: (route) => ({
                        propertyId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/mainMeters',
                name: 'property-manager.property.mainMeters',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyMeters.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        propertyId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/occupant-analysis',
                name: 'property-manager.property.occupant-analysis',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyOccupantAnalysis.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        propertyId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/analysis/smart-meter',
                name: 'property-manager.property.analysis.smart-meter',
                components: {
                    default: () =>
                        import('./Pages/PropertyManager/PropertySmartMeterAnalysisPage.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        objectType: 'properties',
                        objectId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/smart-meters',
                name: 'property-manager.property.smart-meters',
                components: {
                    default: () =>
                        import('./Pages/PropertyManager/PropertySmartMeterManagementPage.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        objectType: 'properties',
                        objectId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/smart-meters/:smartMeterId(\\d+)',
                name: 'property-manager.property.smart-meters.import-data',
                components: {
                    default: () => import('./Pages/Meters/SmartMeterDataImportOverview.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        objectType: 'properties',
                        objectId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/flats',
                name: 'property-manager.property.flats',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyFlatOverview.vue'),
                    info: () =>
                        import(
                            './components/PropertyManager/FlatSearchWithPropertyInformation.vue'
                        ),
                },
                props: {
                    default: (route) => ({
                        propertyId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/flats/:flatId(\\d+)/dashboard',
                name: 'property-manager.property.flat.dashboard',
                components: {
                    default: () => import('./Pages/PropertyManager/FlatDashboard.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    info: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/flats/:flatId(\\d+)/consumption-analysis',
                name: 'property-manager.property.flat.consumption-analysis',
                components: {
                    default: () =>
                        import('./Pages/PropertyManager/FlatConsumptionAnalysisPage.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                    info: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/flats/:flatId(\\d+)/room-analysis',
                name: 'property-manager.property.flat.room-analysis',
                components: {
                    default: () => import('./Pages/Flats/FlatRoomAnalysis.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                        enableRoomEdit: false,
                    }),
                    info: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/flats/:flatId(\\d+)/meter-overview',
                name: 'property-manager.property.flat.meter-overview',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyFlatMeterOverview.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                    info: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: 'properties/compare',
                name: 'property-manager.properties.compare',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyComparison.vue'),
                    info: () => import('./components/PropertyManager/PropertyComparisonSearch.vue'),
                },
            },
            {
                path: 'properties/vacancy/:energyCarrierId(\\d+)?',
                name: 'property-manager.properties.vacancy',
                props: {
                    default: (route) => ({
                        energyCarrierId: route.params.energyCarrierId
                            ? parseInt(route.params.energyCarrierId, 10)
                            : undefined,
                    }),
                },
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyVacancy.vue'),
                    info: () => import('./components/PropertyManager/PropertySearch.vue'),
                },
            },
            {
                path: 'properties/alarm-analysis',
                name: 'property-manager.properties.alarm-analysis',
                props: {
                    default: true,
                },
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyAlarmAnalysis.vue'),
                    info: () => import('./components/PropertyManager/PropertySearch.vue'),
                },
            },
            {
                path: 'properties',
                name: 'property-manager.properties',
                components: {
                    default: () => import('./Pages/PropertyManager/PropertyOverview.vue'),
                    info: () => import('./components/PropertyManager/PropertySearch.vue'),
                },
            },
            {
                path: 'energy-communities/:energyCommunityId(\\d+)/analyse',
                name: 'property-manager.energy-community.analyse',
                components: {
                    default: () =>
                        import('./Pages/PropertyManager/EnergyCommunityAnalysisPage.vue'),
                    info: () => import('./components/PropertyManager/EnergyCommunityInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                    }),
                    info: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                    }),
                },
            },
            {
                path: 'energy-communities/:energyCommunityId(\\d+)/participants',
                name: 'property-manager.energy-community.participants',
                components: {
                    default: () =>
                        import(
                            './Pages/PropertyManager/PropertyManagerEnergyCommunityParticipants.vue'
                        ),
                    info: () => import('./components/PropertyManager/EnergyCommunityInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                    }),
                    info: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                    }),
                },
            },
            {
                path: 'energy-communities',
                name: 'property-manager.energy-communities',
                components: {
                    default: () =>
                        import(
                            './Pages/PropertyManager/PropertyManagerEnergyCommunityOverview.vue'
                        ),
                    info: () => import('./components/PropertyManager/EnergyCommunitySearch.vue'),
                },
            },
            {
                path: 'employees',
                name: 'property-manager.employees',
                components: {
                    default: () => import('./Pages/Employees.vue'),
                    info: () => import('./components/EmployeeInfoSearch.vue'),
                },
                props: {
                    default: () => ({
                        propertyManagerId: useAuthStore().loggedInUserId,
                    }),
                },
            },
            {
                path: 'employees/:userId(\\d+)/assign-property',
                name: 'property-manager.employee.assign-property',
                props: {
                    default: true,
                },
                components: {
                    default: () => import('./Pages/PropertyManager/EmployeeAssignProperties.vue'),
                    info: () => import('./components/PropertyManager/PropertySearch.vue'),
                },
            },
            {
                path: 'employees/:userId(\\d+)/edit',
                name: 'property-manager.employee.edit',
                props: {
                    default: (route) => ({}),
                },
                components: {
                    default: () => import('./Pages/Profile.vue'),
                },
            },
            {
                path: 'employees/create',
                name: 'property-manager.employees.create',
                components: {
                    default: () => import('./Pages/UserCreate.vue'),
                },
            },
            {
                path: 'save-energy-tips-flat',
                name: 'property-manager.save-energy-tips-flat',
                components: {
                    default: () => import('./Pages/Flats/SaveEnergyTipsFlat.vue'),
                },
            },
            {
                path: 'save-energy-tips-property',
                name: 'property-manager.save-energy-tips-property',
                components: {
                    default: () => import('./Pages/Properties/SaveEnergyTipsProperty.vue'),
                },
            },
            {
                path: 'help',
                name: 'property-manager.help',
                components: {
                    default: () => import('./Pages/PropertyManager/Help.vue'),
                },
            },
            {
                path: 'profile/:userId(\\d+)',
                name: 'property-manager.profile',
                props: {
                    default: (route) => ({
                        userId: parseInt(route.params.userId, 10),
                    }),
                },
                components: { default: () => import('./Pages/Profile.vue') },
            },
        ],
    },
    {
        path: '/admin',
        redirect: { name: 'admin.dashboard' },
        component: () => import('./layouts/default.vue'),
        meta: { requiresAuth: true, requiresAuthGroup: [1] },
        children: [
            {
                path: 'dashboard',
                name: 'admin.dashboard',
                components: {
                    default: () => import('./Pages/Admin/AdminDashboard.vue'),
                },
            },
            {
                path: 'properties',
                name: 'admin.properties',
                components: {
                    default: () => import('./Pages/Admin/PropertyOverview.vue'),
                    info: () => import('./components/Admin/PropertySearchForm.vue'),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/edit',
                name: 'admin.property.edit',
                components: {
                    default: () => import('./Pages/Admin/PropertyEdit.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/flats',
                name: 'admin.property.flats',
                components: {
                    default: () => import('./Pages/Admin/PropertyFlatOverview.vue'),
                    info: () => import('./components/PropertyManager/PropertyInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        propertyId: parseInt(route.params.propertyId, 10),
                    }),
                    info: (route) => ({
                        propertyId: parseInt(route.params.propertyId, 10),
                    }),
                },
            },
            {
                path: 'properties/:propertyId(\\d+)/flats/:flatId(\\d+)/tenants',
                name: 'admin.property.flat.tenants',
                components: {
                    default: () => import('./Pages/Admin/TenantChange.vue'),
                    info: () => import('./components/FlatInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                    info: (route) => ({
                        flatId: parseInt(route.params.flatId, 10),
                    }),
                },
            },
            {
                path: 'energy-communities/:energyCommunityId(\\d+)/import',
                name: 'admin.energy-community.import',
                components: {
                    default: () =>
                        import('./Pages/Admin/AdminEnergyCommunityDataImportOverview.vue'),
                    info: () => import('./components/PropertyManager/EnergyCommunityInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                    }),
                    info: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                    }),
                },
            },
            {
                path: 'energy-communities/:energyCommunityId(\\d+)/participants',
                name: 'admin.energy-community.participants',
                components: {
                    default: () => import('./Pages/Admin/AdminEnergyCommunityParticipants.vue'),
                    info: () => import('./components/PropertyManager/EnergyCommunityInfo.vue'),
                },
                props: {
                    default: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                        showAddParticipantsModal: route.params.showAddParticipantsModal,
                    }),
                    info: (route) => ({
                        energyCommunityId: parseInt(route.params.energyCommunityId, 10),
                    }),
                },
            },
            {
                path: 'energy-communities/create',
                name: 'admin.energy-communities.create',
                components: {
                    default: () => import('./Pages/EnergyCommunities/CreateEnergyCommunity.vue'),
                },
            },
            {
                path: 'energy-communities',
                name: 'admin.energy-communities',
                components: {
                    default: () => import('./Pages/Admin/AdminEnergyCommunityOverview.vue'),
                    info: () => import('./components/PropertyManager/EnergyCommunitySearch.vue'),
                },
            },
            {
                path: 'property-managers',
                name: 'admin.property-managers',
                components: {
                    default: () => import('./Pages/Admin/PropertyManagerOverview.vue'),
                    info: () => import('./components/Admin/PropertyManagerSearchForm.vue'),
                },
            },
            {
                path: 'property-managers/:propertyManagerId(\\d+)/employees',
                name: 'admin.property-manager.employees',
                components: {
                    default: () => import('./Pages/Employees.vue'),
                },
                props: { default: true },
            },
            {
                path: 'property-managers/:propertyManagerId(\\d+)/employees/create',
                name: 'admin.property-manager.employees.create',
                components: {
                    default: () => import('./Pages/UserCreate.vue'),
                },
            },
            {
                path: 'property-managers/:propertyManagerId(\\d+)/employees/:userId(\\d+)/assign-property',
                name: 'admin.property-manager.employee.assign-property',
                props: {
                    default: true,
                },
                components: {
                    default: () => import('./Pages/PropertyManager/EmployeeAssignProperties.vue'),
                    info: () => import('./components/PropertyManager/PropertySearch.vue'),
                },
            },
            {
                path: 'property-managers/:propertyManagerId(\\d+)/employees/:userId(\\d+)/edit',
                name: 'admin.property-manager.employee.edit',
                props: {
                    default: (route) => ({
                        userId: parseInt(route.params.userId, 10),
                    }),
                },
                components: {
                    default: () => import('./Pages/Profile.vue'),
                },
            },
            {
                path: 'ista-employees',
                name: 'admin.ista-employees',
                components: {
                    default: () => import('./Pages/Admin/IstaEmployeeOverview.vue'),
                    info: () => import('./components/Admin/IstaEmployeeSearchForm.vue'),
                },
            },
            {
                path: 'report-overview',
                name: 'admin.report-overview',
                components: {
                    default: () => import('./Pages/Admin/MailReportOverview.vue'),
                },
            },
            {
                path: 'admins',
                name: 'admin.admin-overview',
                components: {
                    default: () => import('./Pages/Admin/AdminOverview.vue'),
                },
            },
            {
                path: 'profile/:userId(\\d+)',
                name: 'admin.profile',
                props: {
                    default: (route) => ({
                        userId: parseInt(route.params.userId, 10),
                    }),
                },
                components: { default: () => import('./Pages/Profile.vue') },
            },
            {
                path: '*',
                redirect: { name: 'admin.dashboard' },
            },
        ],
    },
];
