import { defineStore } from 'pinia';

export const useEnergyCommunitySearchStore = defineStore('energyCommunitySearch', {
    state: () => ({
        params: {
            name: '',
            external_community_id: '',
            external_eda_partner_id: '',
        },
    }),

    actions: {
        setSearchParams(params) {
            this.params = { ...params };
        },

        resetSearchParams() {
            this.params = {
                name: '',
                external_community_id: '',
                external_eda_partner_id: '',
            };
        },
    },
});
