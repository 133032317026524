import { Model, useRepo } from 'pinia-orm';
import Meter from './Meter';
import GridOperator from './GridOperator';
import EnergyCommunity from './EnergyCommunity';
import User from './User';

export default class SmartMeter extends Model {
    static entity = 'smart_meters';

    static fields() {
        return {
            id: this.attr(null),
            meter_id: this.attr(null),
            meter_point_number: this.string(null),
            device_type_smart: this.string(null),
            created_by: this.attr(null),
            energy_community_id: this.attr(null),
            grid_operator_id: this.attr(null),
            eda_consent_id: this.attr(null),
            eda_connection_state: this.attr(null),
            eda_connection_state_title: this.attr(null),
            authorize: this.attr([]),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            meter: this.belongsTo(Meter, 'meter_id'),
            gridOperator: this.belongsTo(GridOperator, 'grid_operator_id'),
            energyCommunity: this.belongsTo(EnergyCommunity, 'energy_community_id'),
            creator: this.belongsTo(User, 'created_by'),
        };
    }

    static repo() {
        return useRepo(SmartMeter);
    }
}
