import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

const IstaPreset = definePreset(Aura, {
    primitive: {
        blue: {
            50: '#F8FAFF', // istaBlue5
            100: '#EBF4FE', // istaBlue10
            200: '#D6E8FD', // istaBlue20
            300: '#ABCFFB', // istaBlue30
            400: '#4D9CF8', // istaBlue40
            500: '#0071FF', // istaBlue50
            600: '#0065E4', // istaBlue60
            700: '#0058C6', // istaBlue70
            800: '#0047A1', // istaBlue80
            900: '#003372', // istaBlue90
            950: '#00003C', // istaBlue100
        },
        green: {
            50: '#F6FEF8', // istaGreen5
            100: '#E4FBEB', // istaGreen10
            200: '#C6F7D6', // istaGreen20
            300: '#A3F3BF', // istaGreen30
            400: '#75EFAA', // istaGreen40
            500: '#1BEB83', // istaGreen50
            600: '#18D275', // istaGreen60
            700: '#15B665', // istaGreen70
            800: '#119553', // istaGreen80
            900: '#0C693B', // istaGreen90
            950: '#003C32', // istaGreen100
        },
        red: {
            50: '#FDECE7', // istaRed5
            100: '#F7C3B5', // istaRed10
            200: '#F4A790', // istaRed20
            300: '#EE7F5D', // istaRed30
            400: '#EB663D', // istaRed40
            500: '#E43E0C', // istaRed50
            600: '#D0390B', // istaRed60
            700: '#A52009', // istaRed70
            800: '#7E2207', // istaRed80
            900: '#511805', // istaRed90
            950: '#491404', // istaRed100
        },
        yellow: {
            50: '#FEF7E7', // istaYellow5
            100: '#FBE6B1', // istaYellow10
            200: '#F9DA8B', // istaYellow20
            300: '#f6c955', // istaYellow30
            400: '#F5BE32', // istaYellow40
            500: '#F0AC00', // istaYellow50
            600: '#DB9D00', // istaYellow60
            700: '#AD7C00', // istaYellow70
            800: '#855F00', // istaYellow80
            900: '#664B00', // istaYellow90
            950: '#4D3800', // istaYellow100
        },
        slate: {
            // Using ISTA Base colors for slate
            50: '#F4F7F9', // istaBase5
            100: '#EDF1F5', // istaBase10
            200: '#E2E8F0', // istaBase20
            300: '#CBD5E3', // istaBase30
            400: '#94A3B8', // istaBase40
            500: '#64748B', // istaBase50
            600: '#475569', // istaBase60
            700: '#334155', // istaBase70
            800: '#1E293B', // istaBase80
            900: '#192131', // istaBase90
            950: '#00001B', // istaBase100
        },
    },
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}', // brand-primary: $istaBlue50
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}', // link-color: $istaBlue80
            900: '{blue.900}',
            950: '{blue.950}', // brand-dark: $istaBlue100
        },
        success: {
            color: '{green.500}', // brand-success: $istaGreen50
            backgroundColor: '{green.300}', // state-success-bg: $istaGreen30
            textColor: '{green.900}', // state-success-text: $istaGreen90
        },
        info: {
            color: '{blue.400}', // brand-info: $istaBlue40
            backgroundColor: '{blue.200}', // state-info-bg: $istaBlue20
            textColor: '{blue.800}', // state-info-text: $istaBlue80
        },
        warning: {
            color: '{yellow.500}',
            backgroundColor: '{yellow.50}', // state-warning-bg: $istaYellow5
            textColor: '{yellow.800}', // state-warning-text: $istaYellow80
        },
        danger: {
            color: '{red.500}',
            backgroundColor: '{red.200}', // state-danger-bg: $istaRed20
            textColor: '{red.700}', // state-danger-text: $istaRed70
        },
        surface: {
            ground: '{slate.50}', // body-bg: $istaBase5
            section: '{slate.100}', // $istaBase10
            card: '#ffffff', // table-bg: white
            border: '{slate.500}', // btn-default-border: $istaBase50
            hover: '{slate.200}',
            text: {
                primary: '{blue.950}', // text-color: $istaBlue100
                secondary: '{slate.500}', // gray: $istaBase50
                color: '{blue.950}', // text-color: $istaBlue100
                muted: '{slate.500}', // gray: $istaBase50
            },
        },
    },
    components: {
        button: {
            colorScheme: {
                light: {
                    outlined: {
                        primary: {
                            borderColor: '{primary.500}',
                        },
                    },
                },
            },
            label: {
                fontWeight: '700',
            },
        },
        datatable: {
            columnTitleFontWeight: '700',
            colorScheme: {
                light: {
                    headerCell: {
                        background: '{slate.100}',
                        color: '{slate.800}',
                        selectedColor: '{green.500}',
                    },
                },
            },
        },
        paginator: {
            root: {
                background: '{slate.50}',
                color: '{slate.500}',
            },
        },
    },
});

export default IstaPreset;
